html,
body {
    margin: 0;
    font-family: 'Nunito';
    font-size: 16px;
}

* {
    box-sizing: border-box;
}

input {
    font-family: 'Nunito';
}

a {
    text-decoration: none;
}
