.input-range {
    position: relative;
}

input[type='range'] {
    -webkit-appearance: none;
    width: 243px;
    margin: 11px 0;
    background-color: rgba(197, 210, 241, 0.26);
    border-radius: 4px;
}
input[type='range']:focus {
    outline: none;
}
input[type='range']::-webkit-slider-runnable-track {
    width: 100%;
    height: 8px;
    cursor: pointer;
    box-shadow: 1px 1px 1px rgba(0, 0, 0, 0), 0px 0px 1px rgba(13, 13, 13, 0);
    background: rgba(197, 210, 241, 0.25);
    border-radius: 4px;
    border: 0px solid rgba(255, 255, 255, 0);
}
input[type='range']::-webkit-slider-thumb {
    box-shadow: 1px 1px 3.4px rgba(0, 0, 0, 0.5), 0px 0px 1px rgba(13, 13, 13, 0.5);
    border: 5px solid #ffffff;
    height: 30px;
    width: 30px;
    border-radius: 50px;
    background: #ff31a4;
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -11px;
}
input[type='range']:focus::-webkit-slider-runnable-track {
    background: rgba(213, 223, 245, 0.25);
}
input[type='range']::-moz-range-track {
    width: 100%;
    height: 8px;
    cursor: pointer;
    box-shadow: 1px 1px 1px rgba(0, 0, 0, 0), 0px 0px 1px rgba(13, 13, 13, 0);
    background: rgba(197, 210, 241, 0.25);
    border-radius: 4px;
    border: 0px solid rgba(255, 255, 255, 0);
}
input[type='range']::-moz-range-thumb {
    box-shadow: 1px 1px 3.4px rgba(0, 0, 0, 0.5), 0px 0px 1px rgba(13, 13, 13, 0.5);
    border: 5px solid #ffffff;
    height: 30px;
    width: 30px;
    border-radius: 50px;
    background: #ff31a4;
    cursor: pointer;
}
input[type='range']::-ms-track {
    width: 100%;
    height: 8px;
    cursor: pointer;
    background: transparent;
    border-color: transparent;
    color: transparent;
}
input[type='range']::-ms-fill-lower {
    background: rgba(181, 197, 237, 0.25);
    border: 0px solid rgba(255, 255, 255, 0);
    border-radius: 8px;
    box-shadow: 1px 1px 1px rgba(0, 0, 0, 0), 0px 0px 1px rgba(13, 13, 13, 0);
}
input[type='range']::-ms-fill-upper {
    background: rgba(197, 210, 241, 0.25);
    border: 0px solid rgba(255, 255, 255, 0);
    border-radius: 8px;
    box-shadow: 1px 1px 1px rgba(0, 0, 0, 0), 0px 0px 1px rgba(13, 13, 13, 0);
}
input[type='range']::-ms-thumb {
    box-shadow: 1px 1px 3.4px rgba(0, 0, 0, 0.5), 0px 0px 1px rgba(13, 13, 13, 0.5);
    border: 5px solid #ffffff;
    height: 30px;
    width: 30px;
    border-radius: 50px;
    background: #ff31a4;
    cursor: pointer;
    height: 8px;
}
input[type='range']:focus::-ms-fill-lower {
    background: rgba(197, 210, 241, 0.25);
}
input[type='range']:focus::-ms-fill-upper {
    background: rgba(213, 223, 245, 0.25);
}
